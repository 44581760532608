import { useStaticQuery, graphql } from 'gatsby';

export const useArchiveData = () => {
    const ArchiveData = useStaticQuery(
        graphql`
            query GET_ARCHIVE_DATA {
                allWordpressPost(sort: { order: DESC, fields: date }) {
                    nodes {
                        date
                        slug
                        title
                        id
                        wordpress_id
                    }
                }
            }
        `
    );
    return ArchiveData;
};
