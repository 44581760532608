import React from 'react';

// const Search = () => (
//     <div className="sidewidget">
//         <h3>SEARCH BLOG</h3>
//     </div>
// );

const Search = () => <div></div>;

export default Search;
