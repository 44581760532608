import React from 'react';
import { Link } from 'gatsby';
import Sidebar1 from '../../images/sidebar1.png';

const Shedule = () => (
    <div className="sidewidget widgetnopadd">
        <div className="sidebarimg">
            <img src={Sidebar1} alt="sidebar" />
            <Link to="/schedule" className="wrtsbtn yellowbtn inlinebtn">
                VIEW OUR SCHEDULE
            </Link>
        </div>
    </div>
);

export default Shedule;
