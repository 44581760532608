import { useStaticQuery, graphql } from 'gatsby';

export const useAllEvents = () => {
    const allEvents = useStaticQuery(
        graphql`
            query GET_ALL_EVENTS {
                allWordpressWpEventsPostType {
                    nodes {
                        slug
                        title
                        id
                        excerpt
                        date
                        featured_media {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        srcSetWebp
                                        srcSet
                                        srcWebp
                                        src
                                    }
                                }
                            }
                        }
                        acf {
                            event_date
                        }
                    }
                }
            }
        `
    );
    return allEvents.allWordpressWpEventsPostType.nodes;
};
