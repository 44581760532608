import React from 'react';
import Sidebar2 from '../../images/sidebar2.png';

const Shop = () => (
    <div className="sidewidget widgetnopadd">
        <div className="sidebarimg">
            <img src={Sidebar2} alt="sidebar 2" />
            <a
                href="https://shop.werockthespectrumkidsgym.com"
                target="_blank"
                rel="noopener noreferrer"
                className="wrtsbtn yellowbtn inlinebtn"
            >
                CHECK OUT OUR ROCK SHOP
            </a>
        </div>
    </div>
);

export default Shop;
