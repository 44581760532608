import { useStaticQuery, graphql } from 'gatsby';

export const useAllCategories = () => {
    const allCategories = useStaticQuery(
        graphql`
            query GET_ALL_CATEGORIES {
                allWordpressCategory(filter: { count: { gt: 0 } }) {
                    nodes {
                        name
                        slug
                        count
                    }
                }
            }
        `
    );
    return allCategories.allWordpressCategory.nodes;
};
