import React from 'react';
import { Link } from 'gatsby';
import { useAllCategories } from '../../hooks/getAllCategories';

const SidebarCategories = () => {
    const categories = useAllCategories();
    return (
        <div className="sidewidget">
            <h3>CATEGORIES</h3>

            {categories.map(cat => (
                <h5 key={cat.name}>
                    <Link
                        to={`/category/${cat.slug}`}
                        dangerouslySetInnerHTML={{
                            __html: cat.name,
                        }}
                    />
                </h5>
            ))}
        </div>
    );
};

export default SidebarCategories;
